import React from 'react';
import styled from 'styled-components';

const AboutMeDiv = styled.div`
    margin-left:20vw;
    margin-right:20vw;
    display: flex;
    flex-direction: column;
    color:#696969;
    @media (max-width:768px) {
        flex-direction: column;
        margin :5vw;
    }
`
const StyledImg = styled.img`
    margin-top:15%;
    max-width:20vw;
    max-height:50vh;;
    width:auto;
    height:auto;
    
    @media (max-width: 768px) {
        margin-top:0%;
        max-width:50vw;
        max-height:50vh;
        margin-left:25%;
        margin-right:25%;
        margin-bottom:15%;
    }
    

`


const StyledSpan = styled.span`
    color:#00ffc2;
    font-family: "Calibre","San Francisco","SF Pro Text";

`
const BodyDiv = styled.div`

    display:flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    

`
const BodySubDiv = styled.div`

    display:flex;
    flex-direction: column;
    padding:5%;
    p{
        margin:2px;
    }
    .my-skill{
        display:flex;
        flex-direction: row;
        .skill-list-one{
            margin-left:0;
        }
        .skill-list-two{
            margin-left:40px;
        }

    }

`

const About = () => {
    return (
        <AboutMeDiv>
            <h3><StyledSpan>01.</StyledSpan> About</h3>
            <BodyDiv>
                <BodySubDiv>
                    <p>
                        Hello! I'm Elo, a GRA at OSU.
                    </p>
                    <p>
                        I love learning and applying my knowledge. I am passionate about solar energy and desalination technologies as well as 
                        AI, Robotics and software (webapps/mobile apps/PC Software) creation. 
                    </p>
                    <p>
                        After a year as a Test Technician, I a decided to return to OSU to continue my research on critical heat flux in pool boiling.
                        As a student, I have been able to dedicate more of my leisure hours to working on some startup ideas.                        
                    </p>
                    <p>
                        Here are a few softwares/technologies I currently use:
                    </p>                 

                    <ul className='my-skill'>
                        <div className='skill-list-one'>
                            <li>Deep/Machine Learning</li>
                            <li>Simulation (LBM/VOF)</li>
                            <li>3D Design</li>
                            <li>Software design</li>
                        </div>
                        <div className='skill-list-two'>
                            <li>Python</li>
                            <li>C++|Ansys Fluent</li>
                            <li>Solid works</li>
                            <li>React|Flask|MongoDB|Nginx</li>
                        </div>



                    </ul>

                    <p>Funding I've recieved</p>
                    <ul className='my-skill'>
                        <div className='skill-list-one'>
                            <li>Thesis/Research - Funded by United States Bureau of Reclamation (USBR)</li>
                            <li>I-Corps cohort - Funded by The National Science Foundation (NSF)</li>
                        </div>



                    </ul>
                </BodySubDiv>

                <StyledImg src={process.env.PUBLIC_URL + 'elophoto.jpg'} alt='Elo'/>
            </BodyDiv>

           
            
        </AboutMeDiv>
    )
}

export default About
