import React from 'react';
import styled from 'styled-components';
import ReactTypingEffect from 'react-typing-effect';

const LandingPageDiv = styled.div`
    color:#696969;
    height:90vh;
    margin-left:20vw;
    margin-right:20vw;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        margin-left:5vw;
        margin-right:5vw;

    }


`
const H1 = styled.h1`
    color:#8f8f8f;
    font-size: 66px;
    font-family: Arial, Helvetica, sans-serif;
    margin:0;
    @media (max-width: 768px) {
        font-size: 38px;
    }
    
`

const MyIntro = styled.p`
    margin-right:32vw;
    @media (max-width: 768px) {
        margin-right:5vw;
    }


`

const GetInTouchBtn = styled.button`
    margin-top:5%;
    border-color: #00ffc2;
    color: #00ffc2;
    background-color: transparent;
    width: 10vw;
    padding:20px;
    @media (max-width: 768px) {
        margin-top: 20%;
        width: 40vw;
    }
    :hover{
        color:#696969;
        background-color:#00ffc2;
    }


`


const StyledSpan = styled.span`
    color:#00ffc2;

`
const H4 = styled.h4` 
    margin-top: 20%;
    @media (max-width: 768px) {
        margin-top: 35%;

    }
`

const LandingPage = () => {
    return (
        <LandingPageDiv>
            <H4><StyledSpan>Hi, my name is</StyledSpan></H4>
            <H1>Elo-Oghene Enwa</H1>
            <div>I am <StyledSpan><ReactTypingEffect text={["a Researcher","an Engineer","an aspiring Entrepreneur"]} /></StyledSpan></div> 
            <MyIntro>I am currently a Graduate Research Assistant (GRA) at Oklahoma State University (OSU), Stillwater. My area of research is <StyledSpan>Solar 
                Thermal Desalination</StyledSpan>, with a core focus on enhancing pool boiling heat transfer.</MyIntro>
            <a className="mailto" href="mailto:elooghe@okstate.edu"><GetInTouchBtn >GET IN TOUCH</GetInTouchBtn></a>
        </LandingPageDiv>
    )
}

export default LandingPage
