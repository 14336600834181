import React from 'react';
import styled from 'styled-components';

const WorkDiv = styled.div`
    margin-left:20vw;
    margin-right:20vw;
    display: flex;
    flex-direction: column;
    color: #696969;
    @media (max-width:768px) {
        //flex-direction: column;
        margin :5vw;
    }
`
const StyledImg = styled.img`
    max-width:30vw;
    max-height:40vh;;
    width:auto;
    height:auto;
    padding-top:15%;
    .my-conv.my-conv.my-conv {
        max-width:100vw;
        max-height:100vh;

    }
    
    @media (max-width: 768px) {
        max-width:50vw;
        max-height:50vh;
        margin-left:25%;
        margin-right:25%;
        padding-top:0%;
        padding-bottom:15%;
    }
    

`

const StyledVid = styled.video`
    max-width:30vw;
    max-height:40vh;
    width:auto;
    height:auto;
    padding-top:15%;

    
    @media (max-width: 768px) {
        max-width:50vw;
        max-height:50vh;
        margin-left:25%;
        margin-right:25%;
        padding-top:0%;
        padding-bottom:15%;
    }
    
`

const StyledSection = styled.section`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }


`

const StyledSpan = styled.span`
    color:#00ffc2;
    font-family: "Calibre","San Francisco","SF Pro Text";

`

const BodyDiv = styled.div`
    padding:1%;

    .link-def{
        text-decoration:none;
    }

`
const Keyword = styled.span`
    background-color:#4ba893;
    color: white;
    height: 10px;
    width:20px;
    border-radius: 10px;

    font-size: 18px;
    padding-right:20px;
    padding-left:20px;
    margin:10px;
    @media (max-width:768px) {
        font-size: 10px;}

`

const Work = () => {
    return (
        <WorkDiv>
            <h3><StyledSpan>03.</StyledSpan> MY WORK</h3>
            <StyledSection>
                <BodyDiv>
                    <h3>Research (Experiments)</h3>
                    <Keyword>Solar</Keyword><Keyword>Wettability</Keyword><Keyword>Coating</Keyword>
                    <p>I am working on a novel solar desalination design. 
                        My research group is currently focused on appying recent advancements in pool boiling to increase boiling heat flux and subsequently increase evaporation rate. 
                        My work as a graduate researcher includes designing a pool boiling facility to study heat flux in our proposed desalination design. 
                        I have also performed serveral wettability alteration experiments on copper and brass substrates to make them superhydrophilic for our pool boiling design. 
                        This wettability alteration experiment was accomplised using a layer-by-layer deposition method to coat copper and brass subtrate with TiO2 nanoparticles. 
                        Also, I built a setup to evaluate the efficiency of three different solar coating 
                        (Solec SOLKOTE HI/SORB-II, Thumalox 250 black selective solar coating and Rustoem 2X black spray paint).
                        Lastly, I have also reviewed various numerical methods to model nucleate boiling heat flux for different contact angles.
                    </p>
                </BodyDiv>

                <StyledImg src={process.env.PUBLIC_URL + '/work/image2smaller.jpeg'} alt='Elo'/>
            </StyledSection>
            <StyledSection>
                <BodyDiv>
                    <h3>LBM Simulation</h3>
                    <Keyword>Python</Keyword><Keyword>Fortran</Keyword><Keyword>BGK</Keyword>
                    <p>Here is an example of thermal advection-convection simulation from Dr. A. A Mohammed's book. 
                        Lattice Boltzmann Method: Fundamentals and Engineering Applications with Computer Codes. 
                        The codes in Dr. Mohamad's book are in C++ and Fortran, which are quite harder to understand as compared to codes written in python and Matlab. 
                        Hence, I am making a supplemental python & Matlab workbook for Dr. Mohamad's textbook, since it is one of the most recommended books on the subject. 
                        The simulation displayed was written in python. It shows lattice boltzmann method (LBM) simulation of advection convection in a D2Q9 lattice.
                    </p>

                </BodyDiv>

                <StyledImg src={process.env.PUBLIC_URL + '/work/lbmadvconv.gif'} alt='Elo'/>
            </StyledSection>
            <StyledSection>
                <BodyDiv>                
                    <h3>Python Simulation</h3>
                    <Keyword>Python</Keyword><Keyword>Advection</Keyword><Keyword>Diffusion</Keyword>                    
                    <p>Here is a Numerical simulation I did in my Micro Flows class <br></br>(where we learnt Gas Kinetic Theory). 
                            This is a convection-diffusion simulation of concentration diffusion in a 1cm x 1cm (50 x 50 nodes) domain with a diffusion coefficient (D) = 4mm^2/s. 
                            The code uses a second order upwind differencing scheme to calculate the new concerntration at each node for every time step. 
                            For the convective term, a fully developed couette flow velocity profile with a top plate velocity of 9mm/s was used. 
                            The north and south wall had U = 0 boundary condition (i.e Dirichlet condition - Total adhesion). 
                            While the east wall had a boundary condition dU/dn = 0 (i.e. Homogeneous Neumann condition) and the east wall had a boundary condition of U = 1.
                            <br></br><br></br><img src={process.env.PUBLIC_URL+ '/equation.svg'} alt='2D Diffusion equation'/>

                            
                    </p>
                </BodyDiv>
                <StyledImg className='my-conv' src={process.env.PUBLIC_URL + '/work/concadvconv.gif'} alt='Elo'/>
            </StyledSection>
            <StyledSection>
                <BodyDiv>
                    <h3>LBM Flow Over Cylinder</h3>
                    <Keyword>BGK</Keyword><Keyword>Turbulence</Keyword><Keyword>Fluid flow</Keyword>
                    <p>Fluid flow over a cylinder is the equivalent of hello world in Computational Fluid Dynamic (CFD) simulation. 
                            Here is a lattice boltzmann method (LBM) simulation of fluid fluid over a cylinder at high Reynolds number. 
                            This LBM simulation makes use of the Bhatnagar–Gross–Krook (BGK) collision operator.
                            Behind the cylinder we see an oscillating Karman vortex street wake which is characteristic of turbulent flow. 
                    </p>
                </BodyDiv>

                <StyledImg src={process.env.PUBLIC_URL + 'LBMFLOW.gif'} alt='Elo'/>
            </StyledSection>
            <StyledSection>
                <BodyDiv>
                    <h3>Pool Boiling</h3>
                    <Keyword>Fluent</Keyword><Keyword>VOF</Keyword><Keyword>Multiphase</Keyword>
                    <p>Here is a 3D simulation of pool boiling done using Ansys Fluent. 
                            This simulation is a first in a series of simulations done to evaluate the boiling performance of our proposed desalination design 
                            Lee's model was used in the multiphase Volume of Fluid (VOF) model to produce the boiling simulation. 
                            However, after a series of literature reviews I discovered that Ansys fluent is not the right tool for pool boiling simulation because
                             the molecular interation (capillarity,..etc) are left out in continuum based physics. 
                    </p>
                </BodyDiv>
                <StyledVid muted autoPlay loop > <source src={process.env.PUBLIC_URL + '/work/boiling_sim.mp4'}></source> </StyledVid>    
            </StyledSection>
            <StyledSection>
                <BodyDiv>
                    <h3>LBM Multiphase: bubble coalesence</h3>
                    <Keyword>Psuedopotential</Keyword><Keyword>Shen-Chan</Keyword><Keyword>MRT</Keyword>
                    <p>This is a Lattice Boltzmann Method (LBM) multiphase simulation done using Palabos. 
                        <a className ='link-def' href='https://www.sciencedirect.com/science/article/pii/S0898122120301267'><StyledSpan> "Palabos is a C++ software platform developed since 2010 for Computational Fluid Dynamics simulations and Lattice Boltzmann modeling,
                        which specifically targets applications with complex, coupled physics" </StyledSpan></a>
                            . For this simulation the Shen-Chan psuedopotential LBM method was applied to simulate bubble coalesence in a D2Q9 lattice. 
                            Multiphase simulation is difficult to implement on VOF solvers like Ansys Fluent and COMSOL. 
                            Hence, shen-chan psuedopotential LBM has emerged as the best tool for modeling multiphase problems. 
                            Also, this method has proven effective in modeling the 4 different pool boiling regimes (natural convection regime, nucleate boiling regime, transition boiling regime and film boiling regime).
                    </p>

                </BodyDiv>

                <StyledImg src={process.env.PUBLIC_URL + '/work/bubblecoal.gif'} alt='Elo'/>
                
            </StyledSection>

            
        </WorkDiv>
    )
}

export default Work
