import React from 'react'
import styled from 'styled-components';




const StyledSideSpan = styled.div`    
  right:5vw;
  bottom:0;
  position: fixed;
  transform: translateZ(0);
  height: 10vh;
  width:1px;
  background-color:white;
  @media (max-width:768px) {
        display:none;
    }
`
const P = styled.div`
    color:white;
    transform:rotate(90deg);
    height: 14vh;
    position: fixed;
    right: 5vw;
    bottom:260px;
    width:1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    :hover{
        color:#00ffc2;
    }
    @media (max-width:768px) {
        display:none;
    }
`


const RightSTDiv = () => {
    return (
        <div>
                <P>elooghe@okstate.edu</P>
                <StyledSideSpan />

                
               
           
        </div>
    )
}

export default RightSTDiv
