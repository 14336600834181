import React from 'react';
import styled from 'styled-components';




const NavDiv = styled.div`
    margin-left :5vw;
    margin-right :5vw;
    display: flex;
    flex-direction: column;
    color: #696969;
`

const StyledSpan = styled.span`
    color:#00ffc2;
`
const StyledList = styled.li`
    :hover {
        color:#00ffc2;

    }

`

const Alink = styled.a`
    text-decoration: none;
    color:#8f8f8f;
    padding:1rem;

`
const StyledUl = styled.ul`
    top:0%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    @media (max-width: 768px) {
        display:none;
    }


`



const StyledA = styled.a` 
    margin-top:0.8%;
    text-decoration:none;
    color:#00ffc2;
    outline: 2px solid #00ffc2;
    width: 4vw;
    height: 2.7vh;
    padding-right:10px;
    padding-left:10px;
    padding-top:10px;
    text-align: center;
    font-size: 12px;

    @media (max-width: 768px) {
        visibility:hidden;
    }



`


const Navbar = () => {
    return (
        <NavDiv>
            <StyledUl>
                <Alink href='#about'><StyledList><StyledSpan>01. </StyledSpan>About</StyledList></Alink>
                <Alink href='#experience'><StyledList><StyledSpan>02. </StyledSpan>Experince</StyledList></Alink>
                <Alink href='#work'><StyledList><StyledSpan>03. </StyledSpan>Work</StyledList></Alink>
                <Alink href='#contact'><StyledList><StyledSpan>04. </StyledSpan>Contact</StyledList></Alink>                            
                
                <StyledA className='button' href='/Resume/eloenwa_resume.pdf' > Resume </StyledA>
            </StyledUl>
        </NavDiv>
    )
}

export default Navbar
