import React, {useState} from 'react';
import styled from 'styled-components';
import RenderWork from './WorkHistory/RenderWork';

const ExperienceDiv = styled.div`

    margin-left:20vw;
    margin-right:20vw;
    display: flex;


    color:#696969;
    @media (max-width:768px) {
        flex-direction: column;
        margin :5vw;
        height: 550px;
        padding-bottom:20px;

    }

`

const Maindiv = styled.div`
    margin-top:100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    @media (max-width:768px) {
        flex-direction: column;
        width:100%;
        height: 120px;
        padding: 1px;
        margin-top:10px;
        margin-bottom: 10px;

    }

`
const ButtonDiv = styled.div`
    margin:auto;
    height: 30vh;
    width: 10vw;
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    background-color:transparent;
    @media (max-width:768px) {
        width:100%;
        height: 30px;
        top:0;
        overflow-x: auto;
        //overflow-y:hidden;
        padding-top:20px;
        padding-bottom:20px;
        background-color:transparent;

        .wrapper-div{
            align-items:center;
            height:10%;
            display:flex;
            flex-direction: row;
            margin-right:-700px;
            margin-left:2px;
            margin-top:0px;
            background-color:pink;
        }
               
    }

`

const DisplayDiv = styled.div`
    margin:auto;
    background-color: transparent;
    width: 35vw;
    height: 30vh;
    @media (max-width: 768px) {
        background-color:transparent;
        width:80vw;
        height:20vh;
        position:relative;
        margin-left:-15px;
        margin-top:0px;

    }


`

const ContBtn = styled.button`
    border-color: #00ffc2;
    color: #00ffc2;
    background-color: transparent;
    width: 9vw;
    padding:15px;
    @media (max-width: 768px) {
        width:100%;
        margin-left:15px;
        margin-left:15px;
        height:20px;
        width:200px;
        text-align: center;
        padding:15px;


    }


`

const StyledSpan = styled.span`
    color:#00ffc2;
    font-family: "Calibre","San Francisco","SF Pro Text";

`


const Experience = () => {
    const [selected,setSelected] = useState('')

    

    return (
        <ExperienceDiv>
            <h3><StyledSpan>02.</StyledSpan> Experience</h3>
            <Maindiv>
                <ButtonDiv>
                    <div className='wrapper-div'>
                        <ContBtn onClick={()=>setSelected('Grad Researcher')}>GRA</ContBtn>
                        <ContBtn onClick={()=>setSelected('Test Technician')}>Test Technician</ContBtn>
                        <ContBtn onClick={()=>setSelected('Researcher Assist')}>Research Asst</ContBtn>
                        <ContBtn onClick={()=> setSelected('Masters')}>Masters</ContBtn>
                        <ContBtn onClick={() => setSelected('Undergrad')}>Undergrad</ContBtn>
                    </div>


                </ButtonDiv>
                <DisplayDiv>
                    <RenderWork props={selected}/>

                </DisplayDiv>


            </Maindiv>
        </ExperienceDiv>
    )
}

export default Experience
