import React from 'react';
import MastersEd from './MastersEd';
import UnderGRA from './UnderGRA';
import GRA from './GRA';
import TesTTech from './TesTTech';
import MechEng from './MechEng';

const RenderWork = (props) => {
    if(props.props==="Researcher Assist"){
    return (
            <div>
                <UnderGRA />
            </div>
        )
    }else if(props.props==="Test Technician"){
        return (
                <div>
                    <TesTTech />
                </div>
            )
        }else if(props.props==="Undergrad"){
            return (
                    <div>
                        <MechEng />
                    </div>
                )
            }else if(props.props==="Masters"){
                return (
                        <div>
                            <MastersEd />
                        </div>
                    )
                }else {
                    return (
                    <div>
                        <GRA />
                    </div>
                )

                }
    
}

export default RenderWork





