import React from 'react';
import styled from 'styled-components';

const ContactMeDiv = styled.div`
    margin-left:35vw;
    margin-right:35vw;
    width: 30vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:#696969;
    align-self: center;
    align-items:center;
    .get-in-touch {
        font-size:44px;
        font-weight: bold;
    }
    p{
        vertical-align: middle; 
        text-align: center; 
        position: relative;
        top:-20px;
    }
    .foot-note{
        font-size:14px;
        position: relative;
        margin-top:200px;
    }

    @media (max-width:768px) {
        flex-direction: column;
        width:90vw;

        margin-right:5vw;
        margin-left:5vw;
    }
`

const GetInTouchBtn = styled.button`
    margin-top:5%;
    border-color: #00ffc2;
    color: #00ffc2;
    background-color: transparent;
    width: 10vw;
    padding:20px;
    @media (max-width: 768px) {
        margin-top: 20%;
        width: 40vw;
    }
    :hover{
        color:#696969;
        background-color:#00ffc2;
    }


`

const StyledSpan = styled.span`
    color:#00ffc2;
    font-family: "Calibre","San Francisco","SF Pro Text";

`

const Contact = () => {
    return (
        <ContactMeDiv>
            <h4><StyledSpan>04. </StyledSpan>What's Next?</h4>
            <p className='get-in-touch'>Get In Touch</p>
            <p> I am open to collaborating and exciting opportunities, hence feel free to contact me at anytime. I'll try my best to get back to you!</p>
            <a className="mailto" href="mailto:elooghe@okstate.edu"><GetInTouchBtn>Say Hello</GetInTouchBtn></a>
            <p className='foot-note'>Designed & Built by Elo Enwa</p>

        </ContactMeDiv>
    )
}

export default Contact
