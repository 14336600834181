import React from 'react';
import styled from 'styled-components';

const MainDiv = styled.div`
    display:flex;
    flex-direction: column;
    font-size:14px;


    height: 100%;
    width:100%;
    margin-left:20px;
    h4{
        margin-left: 20px;
        font-size:20px;

    }
    .month-year {
        position:relative;
        top:-40px;
        margin-left: 20px;
        font-size:14px;
    }
    .description-text {
        position:relative;
        margin-left: 20px;
        top:-35px;
        display: flex;
        flex-direction: column;
    }
    li {
        margin-top: 5px;
        margin-bottom:5px;
    }
    a{
        text-decoration:none;
        color:#00ffc2;
    }

`
const StyledSpan = styled.span`
    color:#00ffc2;

`

const MechEng = () => {
    return (
        <MainDiv>
            <h4>Mechanical Engineering BS<StyledSpan> @ </StyledSpan>OSU</h4>
            <p className='month-year'>August 2013 - May 2018</p>
            <ul className='description-text'>
                <li>Understanding of heat transfer and energy conversion concepts</li>
                <li>Understanding of theoretical fluid mechanics and computational fluid mechanics</li>
                <li>Math classes include Calculus I-III, differential equations, linear algebra & numerical methods</li>
                <li>Design & programming skills <a href='https://3dprint.com/3124/okstate-engineering/'>(link to design)</a></li>
            </ul>


            
        </MainDiv>
    )
}

export default MechEng
