import styled from 'styled-components';
import NavBar from './components/Navbar';
import About from './components/About';
import Experince from './components/Experience';
import Work from './components/Work';
import Contact from './components/Contact';
import LandingPage from './components/LandingPage';
import RightSTDiv from './components/RightSTDiv';
import LeftSTDiv from './components/LeftSTDiv';
import OtherWork from './components/OtherWork';
import './App.css';
import React,{useEffect} from "react";
import ReactGA from "react-ga";  //Google Analytics


const MainDiv = styled.div`
  background-image: linear-gradient(to top, #010F19 0%, #021B2E 100%);
  //height:500vh;
  width:100%;
  color:#00ffc2;
  font-size: 20px;
  @media (max-width:768px) {
    font-size:12px;
  }

`


function App() {


  useEffect(() => {
    ReactGA.initialize('UA-186381428-1');
    ReactGA.pageview(window.location.pathname);
  })
  

  return (
    <MainDiv>
      <div>
        <NavBar />
        <LandingPage />
        <div id="about">
          <About />
        </div>
        <div id="experience" >
          <Experince />
        </div>
        <div  id="work">
          <Work/>
        </div>        
        <OtherWork />
        <div id="contact">
          <Contact />
        </div>
        
        <footer style={{textAlign:"center",color:"transparent"}}>Designed & Built by Elo Enwa</footer>
      </div>
      <RightSTDiv />
      <LeftSTDiv />


      
      
    </MainDiv>
  );
}

export default App;
