import React from 'react';
import styled from 'styled-components';

const StyledSideSpan = styled.div`    
  left:5vw;
  bottom:0;
  position: fixed;
  transform: translateZ(0);
  height: 10vh;
  width:1px;
  background-color:white;
  @media (max-width:768px) {
        display:none;
    }
`
const IconsDiv = styled.div`
    color: white;
    height: 14vh;
    position: fixed;
    left: 4.2vw;
    bottom:140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a{
        color:white;
    }

    @media (max-width:768px) {
        display:none;
    }
`
const CoverDivIcon = styled.div`
    :hover{
        color:#00ffc2;
    }
`

const LeftSTDiv = () => {
    return (
        <div>
            <IconsDiv>
                <a href='https://github.com/eloenwa'><CoverDivIcon><i className="fa fa-github" style={{fontSize:'26px'}}></i></CoverDivIcon></a>
                <a href='https://www.instagram.com/thuzp/'><CoverDivIcon><i className="fa fa-instagram" style={{fontSize:'26px'}}></i></CoverDivIcon></a>
                <a href='https://www.linkedin.com/in/eloenwa'><CoverDivIcon><i className="fa fa-linkedin-square" style={{fontSize:'26px'}}></i></CoverDivIcon></a>
            </IconsDiv>
            <StyledSideSpan />
            
        </div>
    )
}

export default LeftSTDiv
