import React from 'react';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';

const breakPoints  = [

    {width:1500,itemsToShow:1},
    {width:1500,itemsToShow:2},
    {width:1500,itemsToShow:3}
]

const Items = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 120%;
  background-color: transparent;
  color: #fff;
  margin: 0 15px;
  font-size: 1.8em;
  @media (max-width:768px) {
    height: 25vh;
    width:100%;
    font-size: 10px;
  }
  .image-other-work {
         object-fit: cover;
         height:95%;
         @media (max-width:768px) {
            width: 100%;
            height: auto;}


    }
`

const OtherWorkDiv = styled.div`
    padding-bottom:20px;
    padding-top:20px;
    margin-left:15vw;
    margin-right:15vw;
    color:#696969;
    @media (max-width:768px) {
        margin :5vw;
        padding-bottom:10px;
        padding-top:10px;
    }

`

const OtherWork = () => {
    return (
        <OtherWorkDiv>
            <h4>Other projects</h4>
            <Carousel breakPoints={breakPoints}>
                <Items>Spelling bee website<img className ='image-other-work' src={process.env.PUBLIC_URL+'/otherwork/image10.png'} alt='spelling bee website'/></Items>
                <Items><h2>tete-a-tete</h2><p>tete-a-tete is a website where you can chat with an AI. This AI is almost indistinguishable from a human in conversation style.
                    this setup is a combination of a React.js frontend a flask backend hosted on a local linux server running Nginx (send me a message to see a demo).</p></Items>
                <Items><h2>is a cat?</h2><p>This is also a website. On this website there is a Convolution Neural Network at the backend trained to distinguish a picture of a cat from a dog.
                    The frontend was made with React.js and the backend Flask. The website is locally hosted on a raspberry pi 3 running Nginx & gunicorn. Inspired by 'Hot Dog/Not a Hot Dog'
                    app from the TV show Silicon Valley </p></Items>
                <Items>Korean writing game<img className ='image-other-work' src={process.env.PUBLIC_URL+'/otherwork/hangul_game.PNG'} alt='hangul game website'/></Items>
                <Items><h2>Stock Screener</h2><p>This is a mid-cap stock screening algorithm that evaluates 3000 companies on the Nasdaq stock exchange and suggests the 10 best companies to invest in.
                    This was made using python yahoo finance library and alpaca api</p></Items>
            </Carousel>
        </OtherWorkDiv>
    )
}

export default OtherWork
