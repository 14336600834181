import React from 'react';
import styled from 'styled-components';

const MainDiv = styled.div`
    display:flex;
    flex-direction: column;
    font-size:14px;

    height: 100%;
    width:100%;
    margin-left:20px;
    h4{
        margin-left: 20px;
        font-size:20px;

    }
    .month-year {
        position:relative;
        top:-40px;
        margin-left: 20px;
        font-size:14px;
    }
    .description-text {
        position:relative;
        margin-left: 20px;
        top:-35px;
        display: flex;
        flex-direction: column;
    }
    li {
        margin-top: 5px;
        margin-bottom:5px;
    }

`
const StyledSpan = styled.span`
    color:#00ffc2;

`

const GRA = () => {
    return (
        <MainDiv>
            <h4>Graduate Research Assistant <StyledSpan>@ </StyledSpan>OSU</h4>
            <p className='month-year'>August 2019 - May 2021</p>
            <ul className='description-text'>
                <li>Perform wettability alteration experiments on copper and brass substrates</li>
                <li>Design and perfrom analysis on pool boiling experimental facility</li>
                <li>Perform heat transfer simulation in Ansys to validate experimental data</li>
                <li>Create automated programs to obtain data from thermocouples</li>
                <li>Conduct pool experiments and document results</li>

            </ul>


            
        </MainDiv>
    )
}

export default GRA
