import React from 'react';
import styled from 'styled-components';

const MainDiv = styled.div`
    display:flex;
    flex-direction: column;
    font-size:14px;


    height: 100%;
    width:100%;
    margin-left:20px;
    h4{
        margin-left: 20px;
        font-size:20px;

    }
    .month-year {
        position:relative;
        top:-40px;
        margin-left: 20px;
        font-size:14px;
    }
    .description-text {
        position:relative;
        margin-left: 20px;
        top:-35px;
        display: flex;
        flex-direction: column;
    }
    li {
        margin-top: 5px;
        margin-bottom:5px;
    }

`
const StyledSpan = styled.span`
    color:#00ffc2;

`

const TesTTech = () => {
    return (
        <MainDiv>
            <h4>Test Technician <StyledSpan>@ </StyledSpan>Air hygiene</h4>
            <p className='month-year'>July 2018 - June 2019</p>
            <ul className='description-text'>
                <li>Transported Emissions testing equipment to various testing sites across the United States</li>
                <li>Calibrated CO2, NOx, O2 and VOC sensors for Emissions test </li>
                <li>Assembled Particulate matter and FTIR testing equipments on the stack for tests</li>
                <li>Performed air emissions test on coal and natural gas fired power plants</li>
                <li>Trained new employees on stack safety protocols</li>

            </ul>


            
        </MainDiv>
    )
}

export default TesTTech
